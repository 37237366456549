<template>
  <div
    class="N700 flex justify-end px-4 pt-5 content-center"
    @mouseleave="removeDropdowns"
  >
    <div class="flex relative gap-4">
      <div
        class="
          flex
          gap-2
          self-center
          cursor-pointer
          bg-[#D8DAE5]
          hover:bg-[#bbbbd0]
          p-2
          h-fit
          w-fit
          rounded-lg
          text-[#2F365A]
        "
        @click="showAppsDropdown = !showAppsDropdown"
      >
        <img
          src="../assets/menu-icons/apps.svg"
          class="appsIcon"
          alt="appicon"
        />
        <p>Apps</p>
      </div>
      <div
        class="
          self-center
          cursor-pointer
          bg-[#D8DAE5]
          hover:bg-[#bbbbd0]
          p-2
          rounded-lg
          flex
          gap-2
        "
        @click="redirectToHelp"
      >
        <img src="../components/assets/Help.svg" alt="help" />
      </div>
      <div
        class="flex gap-4 cursor-pointer"
        @click="showProfiledropdown = !showProfiledropdown"
      >
        <ProfileImageComponent
          :imgSrc="profilePicture"
          :initials="nameToInit(initials)"
        />
        <p class="self-center N800 username">{{ username }}</p>
        <img
          src="@/assets/arrow-down.svg"
          class="cursor-pointer h-fit self-center"
          alt="arrow-down"
        />
      </div>
      <ProfileDropdown v-if="showProfiledropdown" />
      <AppsNavigation v-if="showAppsDropdown" />
    </div>
  </div>

  <!--handle breadcrumbs here-->
  <div class="w-full px-5">
    <div v-if="trackRoute === 'ManageLoans'">
      <BreadCrumbsComponent
        v-if="trackRoute === 'ManageLoans'"
        :breadcrumbs="breadcrumbmanageloans"
      />
    </div>

    <BreadCrumbsComponent
      v-if="trackRoute === 'LoanForms'"
      :breadcrumbs="breadcrumbsloanforms"
    />

    <BreadCrumbsComponent
      v-if="trackRoute === 'SetLoanApprover'"
      :breadcrumbs="breadcrumbsloanapprovers"
    />

    <BreadCrumbsComponent
      v-if="trackRoute === 'LoanType'"
      :breadcrumbs="breadcrumbsloanrtypes"
    />

    <div v-if="trackRoute === 'LoanDetails'">
      <BreadCrumbsComponent
        v-if="trackRoute === 'LoanDetails'"
        :breadcrumbs="breadcrumbloandetails"
      />
    </div>

    <div v-if="trackRoute === 'ViewLoanApplication'">
      <BreadCrumbsComponent
        v-if="trackRoute === 'ViewLoanApplication'"
        :breadcrumbs="breadcrumbspersonalloans"
      />
    </div>

    <BreadCrumbsComponent
      v-if="trackRoute === 'accessLevel'"
      :breadcrumbs="breadcrumbaccesslevel"
    />

    <BreadCrumbsComponent
      v-if="trackRoute === 'accessLevelsub'"
      :breadcrumbs="breadcrumbaccesssublevel"
    />
  </div>
</template>
<script setup lang="ts">
import { computed, ref } from "vue";
import { useStore } from "@/store/index";
import ProfileDropdown from "./ProfileDropdown.vue";
import AppsNavigation from "./AppsNavigation.vue";
import ProfileImageComponent from "@/ui-kit/components/Avatar/ProfileImageComponent.vue";
import { nameToInit } from "@/helpers/utils";
import { useRoute } from "vue-router";
import BreadCrumbsComponent from "@/ui-kit/bread-crumbs/BreadCrumbsComponent.vue";
import router from "@/router";

const store = useStore();
const username = computed(() => store.user.first_name);
const initials = computed(
  () => store.user.first_name + " " + store.user.last_name
);
const profilePicture = computed(() => store.user.profile_image);
const route = useRoute();

const showProfiledropdown = ref<boolean>(false);
const showAppsDropdown = ref<boolean>(false);
const removeDropdowns = () => {
  showAppsDropdown.value = false;
  showProfiledropdown.value = false;
};

//habdle breadcrumb here
const trackRoute = computed(() => {
  return route.name;
});

const breadcrumbmanageloans = ref([
  { label: "Home", to: "/" },
  { label: "Manage loans", to: "/manage-loans" }
]);

const breadcrumbsloanforms = ref([
  { label: "Home", to: "/" },
  { label: "settings", to: "/set-loan-approver" },
  { label: "Loan Approver", to: "/set-loan-approver" }
]);

const breadcrumbsloanrtypes = ref([
  { label: "Home", to: "/" },
  { label: "settings", to: "/set-loan-approver" },
  { label: "Loan types", to: "/loan-type-overview" }
]);

const breadcrumbsloanapprovers = ref([
  { label: "Home", to: "/" },
  { label: "settings", to: "/set-loan-approver" },
  { label: "Loan approvers", to: "/loan-type-overview" }
]);

const breadcrumbloandetails = ref([
  { label: "Home", to: "/" },
  { label: "Manage loans", to: "/manage-loans" },
  { label: "Loans details", to: "/loan-details" }
]);

const breadcrumbspersonalloans = ref([
  { label: "Home", to: "/" },
  { label: "View loans application", to: "/view-loan-details" }
]);

const breadcrumbaccesslevel = ref([
  { label: "Home", to: "/" },
  { label: "settings", to: "/set-loan-approver" },
  { label: "Access level", to: "/accesslevel" }
]);

const breadcrumbaccesssublevel = ref([
  { label: "Home", to: "/" },
  { label: "settings", to: "/set-loan-approver" },
  { label: "Access level", to: "/accesslevel" },
  { label: "Create access level", to: "/accesslevelsub" }
]);

const redirectToHelp = () => {
  router.push("/loans-help");
};
</script>
<style>
@media (max-width: 420px) {
  .profile-img {
    border-radius: 50%;
  }

  .username {
    display: none;
  }
}
</style>
