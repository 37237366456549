<template>
  <div class="mobile_div" :class="{ main_view: homeConditions }">
    <AppsComponent v-if="homeConditions && !store.onboardingPending" />

    <div
      class="container mx-auto pb-4 h-screen relative overflow-auto"
      :class="{ main_page: homeConditions, homePage: !homeConditions }"
    >
      <TopNavbar
        class="pr-12"
        v-if="homeConditions && !store.onboardingPending"
      />

      <div class="rounded-lg p-5 w-full">
        <router-link to="/loans"></router-link>
        <router-view v-slot="{ Component }" class="BG0">
          <keep-alive>
            <component :is="Component" :key="Component" />
          </keep-alive>
        </router-view>
      </div>
    </div>
  </div>

</template>
<script setup lang="ts">
import { computed } from "vue";
import { useRouter } from "vue-router";
import AppsComponent from "./components/AppsComponent.vue";
import TopNavbar from "./components/TopNavbar.vue";
import { useStore } from "./store";


const store = useStore();
const router = useRouter();


const homeConditions = computed(() => {
  return (
    router.currentRoute.value.name != "login" &&
    router.currentRoute.value.name != "forgotPassword" &&
    router.currentRoute.value.name != "CreatePassword" &&
    router.currentRoute.value.name != "ChangePassword" &&
    router.currentRoute.value.name != "ResetPassword" &&
    router.currentRoute.value.name != "SendingEmail"
  );
});


</script>
<style lang="scss">
body {
  overflow-y: hidden;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}


/* For Firefox */
* {
  box-sizing: border-box;
}

/* For WebKit (Chrome, Safari) */
*::-webkit-scrollbar {
  width: 0;
}

/* Optional: Hide the scrollbar track in WebKit */
*::-webkit-scrollbar-track {
  display: none;
}

.main_view {
  background-color: #edeff5;
  background-image: url("assets/dashbaord-background.svg");
  background-repeat: no-repeat;
  background-position: center bottom;
  background-attachment: fixed;
  background-size: contain;
}

.main_page {
  box-sizing: border-box;
  margin-left: 100px;
  height: 100vh;
}

.homePage {
  height: 100dvh;
  box-sizing: border-box;
}

.layout {
  width: 100%;
}

nav {
  padding: 30px;
}

a {
  color: #DD5928;

  &.router-link-exact-active {
    color: #DD5928;
  }
}

// BUTTON STYLYING
.btn-shadow {
  box-shadow: 0px 4px 10px -5px rgba(221, 89, 40, 0.3);
}

.btn-shadow:disabled {
  box-shadow: none;
}

.btn-shadow:hover {
  box-shadow: none;
}

/* SPACING */
// Margin top scales
.SPC-MT-100 {
  margin-top: 8px;
}

.SPC-MT-200 {
  margin-top: 16px;
}

.SPC-MT-300 {
  margin-top: 24px;
}

.SPC-MT-400 {
  margin-top: 32px;
}

.SPC-MT-500 {
  margin-top: 40px;
}

.SPC-MT-600 {
  margin-top: 48px;
}

// Margin bottom Scales
.SPC-MB-100 {
  margin-bottom: 8px;
}

.SPC-MB-200 {
  margin-bottom: 16px;
}

.SPC-MB-300 {
  margin-bottom: 24px;
}

.SPC-MB-400 {
  margin-bottom: 32px;
}

.SPC-MB-500 {
  margin-bottom: 40px;
}

.SPC-MB-600 {
  margin-bottom: 48px;
}

// Margin Left Scales
.SPC-ML-100 {
  margin-left: 8px;
}

.SPC-ML-200 {
  margin-left: 16px;
}

.SPC-ML-300 {
  margin-left: 24px;
}

.SPC-ML-400 {
  margin-left: 32px;
}

.SPC-ML-500 {
  margin-left: 40px;
}

.SPC-ML-600 {
  margin-left: 48px;
}

// Margin Right Scales
.SPC-MR-100 {
  margin-right: 8px;
}

.SPC-MR-200 {
  margin-right: 16px;
}

.SPC-MR-300 {
  margin-right: 24px;
}

.SPC-MR-400 {
  margin-right: 32px;
}

.SPC-MR-500 {
  margin-right: 40px;
}

.SPC-MR-600 {
  margin-right: 48px;
}

/* FONTSTYLES */
.H900 {
  font-weight: 700;
  font-size: 2.074rem;
  line-height: 40px;
}

.H800 {
  font-weight: 700;
  font-size: 1.728rem;
  line-height: 32px;
}

.H700 {
  font-weight: 700;
  font-size: 1.44rem;
  line-height: 28px;
}

.H600 {
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 24px;
}

.H500 {
  font-weight: 700;
  font-size: 1rem;
  line-height: 24px;
}

.H400 {
  font-weight: 700;
  font-size: 0.833rem;
  line-height: 16px;
}

.H300 {
  font-weight: 700;
  font-size: 0.694rem;
  line-height: 12px;
}

.H200 {
  font-weight: 500;
  font-size: 0.694rem;
  line-height: 12px;
}

.H100 {
  font-weight: 700;
  font-size: 0.579rem;
  line-height: 12px;
}

.P300 {
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 24px;
}

.P250 {
  font-weight: 600;
  font-size: 1rem;
  line-height: 24px;
}

.P200 {
  font-weight: 400;
  font-size: 1rem;
  line-height: 24px;
}

.P100 {
  font-weight: 400;
  font-size: 0.833rem;
  line-height: 16px;
}

.P50 {
  font-weight: 400;
  font-size: 0.5813rem;
  line-height: 12px;
}

/* COLOURS */
/* Neutrals */
.N900 {
  color: #101840;
}

.N800 {
  color: #474D66;
}

.N700 {
  color: #696F8C;
}

.N600 {
  color: #8F95B2;
}

.N500 {
  color: #C1C4D6;
}

.N400 {
  color: #D8DAE5;
}

.N300 {
  color: #E6E8F0;
}

.N200 {
  color: #EDEFF5;
}

.N100 {
  color: #F4F6FA;
}

.N75 {
  color: #F9FAFC;
}

.N50 {
  color: #FAFBFF;
}

.N0 {
  color: #FFFFFF;
}

// Orange (ACCENT COLOR)
.O600 {
  color: #893516 !important;
}

.O500 {
  color: #B5461D !important;
}

.O400 {
  color: #DD5928 !important;
}

.O300 {
  color: #E47A53 !important;
}

.O200 {
  color: #EB9C7F !important;
}

.O100 {
  color: #F2BEAB !important;
}

.O50 {
  color: #F9E0D7 !important;
}

// Red
.R600 {
  color: #7D2828 !important;
}

.R500 {
  color: #A73636 !important;
}

.R400 {
  color: #D14343 !important;
}

.R300 {
  color: #EE9191 !important;
}

.R200 {
  color: #F4B6B6 !important;
}

.R100 {
  color: #F9DADA !important;
}

.R50 {
  color: #FDF4F4 !important;
}

// Green
.G600 {
  color: #317159 !important;
}

.G500 {
  color: #429777 !important;
}

.G400 {
  color: #52BD94 !important;
}

.G300 {
  color: #A3E6CD !important;
}

.G200 {
  color: #DCF2EA !important;
}

.G100 {
  color: #EEF8F4 !important;
}

.G50 {
  color: #F5FBF8 !important;
}

.mobile_div {
  display: flex;
}

.container {
  max-width: 100% !important;
  padding: 0 10px 20px 10px !important;
}

.BG0 {
  background-color: #fff;
}

a.disabled {
  pointer-events: none;
  cursor: default;
}

label {
  margin-left: 0 !important;
}

/*@ mobile version*/
@media (max-width: 670px) {
  .main_page {
    box-sizing: border-box;
    margin-left: 0px;
    margin-top: 70px;
  }
}
</style>