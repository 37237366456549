<template>
  <div
    class="settings z-50 BG0"
    :class="{
      'settings-open': store.showAll,
      'settings-closed': !store.showAll
    }"
  >
    <div class="flex items-center cursor-pointer relative">
      <a @click="routeUrl()" class="flex items-center gap-5 px-1">
        <div>
          <AmalitechLogo />
        </div>
        <p
          class="
            text-[27px]
            N700
            font-bold
            sm:hidden
            md:hidden
            lg:block
            xl:block
            mr-24
            hide_view
          "
          v-if="store.showAll"
        >
          AmaliTech
        </p>
      </a>
      <span
        class="
          absolute
          h-6
          w-6
          rounded-full
          flex
          items-center
          py-0
          px-2
          shadow-md
          BG0
          top-8
          -right-6
        "
        @click="store.showAll = !store.showAll"
      >
        <ArrowLeftIcon v-if="store.showAll" />
        <ArrowRightIcon v-else />
      </span>
    </div>

    <HorizontalLine class="mt-4" />
    <div>
      <NavigationComponent
        :item="{ label: 'Your loans', route: yourLoanRoutes }"
        @mouseover="toggleOnYourLoans(true)"
        @mouseout="toggleOnYourLoans(false)"
      >
        <LoansSvg
          :color="onYourLoans || $route.path === '/' || $route.path === '/loans-help' || $route.path.startsWith('/view-loan-details') ? '#DD5928' : '#8F95B2'"
        />
      </NavigationComponent>

      <NavigationComponent
        v-if="accesslevel.permissions.manage_loans_manager"
        :item="{ label: 'Manage Loans', route: manageLoanRoutes }"
        @mouseover="toggleOnManageLoans(true)"
        @mouseout="toggleOnManageLoans(false)"
      >
        <MyWallet
          :color="
            onManageLoans ||
            $route.path === '/manage-loans' ||
            $route.path.startsWith('/loan-details')
              ? '#DD5928'
              : '#8F95B2'
          "
        />
      </NavigationComponent>

      <HorizontalLine class="mt-4" />

      <NavigationComponent
        v-if="
          accesslevel.permissions.manage_loan_forms ||
          accesslevel.permissions.manage_access_level ||
          accesslevel.permissions.manage_loan_approvers ||
          accesslevel.permissions.manage_loan_types
        "
        :item="{ label: 'Settings', route: settingsRoutes }"
        @mouseover="toggleOnSettings(true)"
        @mouseout="toggleOnSettings(false)"
      >
        <SettingsSVG
          :color="
            onSettings ||
            $route.path === '/set-loan-approver' ||
            $route.path === '/set-loan-forms' ||
            $route.path === '/loan-type-overview' ||
            $route.path === '/accesslevelsub' ||
            $route.path === '/accesslevel'
              ? '#DD5928'
              : '#8F95B2'
          "
        />
      </NavigationComponent>
    </div>
  </div>
</template>
<script setup lang="ts">
import SettingsSVG from "@/assets/SettingsIcon.vue";
import LoansSvg from "@/assets/LoansSvg.vue";
import NavigationComponent from "./NavigationComponent.vue";
import { computed, ref } from "vue";
import { useStore } from "../store";
import MyWallet from "@/components/assets/Mywallet.vue";
import { getToken } from "@/services/auth";
import config from "@/config/config";
import ArrowLeftIcon from "@/assets/ArrowLeftIcon.vue";
import ArrowRightIcon from "@/assets/ArrowRightIcon.vue";
import { useAccessLevelStore } from "@/Loans-store/store_access_level";
import AmalitechLogo from "@/components/assets/amalitech-logo.vue";
import HorizontalLine from "@/ui-kit/HorizontalLine.vue";
import { useRoute } from "vue-router";

const emits = defineEmits(["appSelected"]);

const store = useStore();
let accesslevel = useAccessLevelStore();

const onYourLoans = ref(false);
const onManageLoans = ref(false);
const onSettings = ref(false);
const route = useRoute();

const yourLoanRoutes = computed(() => {
  if (route.path === "/") {
    return "/";
  }
  if (route.path === "/view-loan-details") {
    return "/view-loan-details";
  }
  if (route.path === "/loans-help") {
    return "/loans-help";
  }
  return "/";
});

const manageLoanRoutes = computed(() => {
  if (route.path === "/manage-loans") {
    return "/manage-loans";
  }
  if (route.path === "/loan-details") {
    return "/loan-details";
  }
  return "/manage-loans";
});

const settingsRoutes = computed(() => {
  if (route.path === "/set-loan-approver") {
    return "/set-loan-approver";
  }
  if (route.path === "/set-loan-forms") {
    return "/set-loan-forms";
  }
  if (route.path === "/loan-type-overview") {
    return "/loan-type-overview";
  }
  if (route.path === "/accesslevel") {
    return "/accesslevel";
  }
  if (route.path === "/accesslevelsub") {
    return "/accesslevelsub";
  }
  return "/set-loan-approver";
});

const toggleOnYourLoans = (status: boolean) => {
  onYourLoans.value = status;
};

const toggleOnManageLoans = (status: boolean) => {
  onManageLoans.value = status;
};

const toggleOnSettings = (status: boolean) => {
  onSettings.value = status;
};

const loginHint: string = store.usersHint;
const routeUrl = () => {
  const token: string | null = getToken();
  if (token) {
    window.location.href = `${config.mainPageLink}`;
  }
};
</script>
<style scoped>
.settings {
  height: 100vh;
  font-weight: 400;
  font-size: 1rem;
  line-height: 24px;
  padding: 15px 18px;
  color: #474d66;
  border: 1px solid #e6e8f0;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.settings::-webkit-scrollbar {
  display: none;
}

.settings-open {
  width: 283px;
}

.settings-closed {
  width: 84px;
  transition: width 0.15s ease-in-out;
}

ul {
  margin: 15px 0;
}

li {
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.H700 {
  z-index: 2;
}
</style>
